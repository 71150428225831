<template>
  <svg
    width="18px"
    height="19px"
    viewBox="0 0 18 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="number-list-icon" fill="currentColor">
        <g id="Group-7">
          <rect id="Rectangle-Copy-3" x="1" y="7" width="1" height="1" />
          <rect id="Rectangle-Copy-5" x="2" y="7" width="1" height="1" />
          <rect id="Rectangle-Copy-6" x="2" y="8" width="1" height="1" />
          <rect id="Rectangle-Copy-8" x="0" y="10" width="1" height="1" />
          <rect id="Rectangle-Copy-24" x="2" y="9" width="1" height="1" />
          <rect id="Rectangle-Copy-31" x="1" y="9" width="1" height="1" />
          <rect id="Rectangle-Copy-32" x="0" y="9" width="1" height="1" />
          <rect id="Rectangle-Copy-9" x="0" y="11" width="1" height="1" />
          <rect id="Rectangle-Copy-10" x="1" y="11" width="1" height="1" />
          <rect id="Rectangle-Copy-11" x="2" y="11" width="1" height="1" />
          <rect id="Rectangle-Copy-4" x="0" y="7" width="1" height="1" />
          <rect id="Rectangle-Copy-18" x="0" y="0" width="1" height="1" />
          <rect id="Rectangle-Copy-19" x="1" y="0" width="1" height="1" />
          <rect id="Rectangle-Copy-20" x="1" y="1" width="1" height="1" />
          <rect id="Rectangle-Copy-21" x="1" y="2" width="1" height="1" />
          <rect id="Rectangle-Copy-22" x="1" y="3" width="1" height="1" />
          <rect id="Rectangle-Copy-23" x="1" y="4" width="1" height="1" />
          <rect id="Rectangle-Copy-30" x="0" y="4" width="1" height="1" />
          <rect id="Rectangle-Copy-29" x="2" y="4" width="1" height="1" />
          <rect id="Rectangle-Copy-3" x="6" y="8" width="12" height="2.5" />
          <rect id="Rectangle-Copy-3" x="6" y="1" width="12" height="2.5" />
          <rect id="Rectangle-Copy-3" x="2" y="14" width="1" height="1" />
          <rect id="Rectangle-Copy-13" x="2" y="18" width="1" height="1" />
          <rect id="Rectangle-Copy-15" x="1" y="18" width="1" height="1" />
          <rect id="Rectangle-Copy-16" x="0" y="18" width="1" height="1" />
          <rect id="Rectangle-Copy-14" x="2" y="15" width="1" height="1" />
          <rect id="Rectangle-Copy-25" x="2" y="16" width="1" height="1" />
          <rect id="Rectangle-Copy-27" x="1" y="16" width="1" height="1" />
          <rect id="Rectangle-Copy-28" x="0" y="16" width="1" height="1" />
          <rect id="Rectangle-Copy-26" x="2" y="17" width="1" height="1" />
          <rect id="Rectangle-Copy-7" x="1" y="14" width="1" height="1" />
          <rect id="Rectangle-Copy-12" x="0" y="14" width="1" height="1" />
          <rect id="Rectangle-Copy-3" x="6" y="15" width="12" height="2.5" />
        </g>
      </g>
    </g>
  </svg>
</template>
