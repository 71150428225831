<template>
  <div class="web-page-preview">
    <div v-if="!iframeLoaded" class="web-page-loader-container">
      <va-loader class="web-page-loader" />
    </div>
    <iframe
      v-if="url"
      :src="url"
      class="web-page-iframe"
      :class="{ loaded: iframeLoaded }"
      @load="iframeLoaded = true"
    />
    <div class="web-page-link-wrapper">
      <a :href="url" target="_blank" class="web-page-link">{{
        $translate('preview.newWindowButton')
      }}</a>
    </div>
  </div>
</template>

<script>
import VaLoader from '@/components/framework/va-loader.vue';
export default {
  components: {
    VaLoader,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iframeLoaded: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.web-page-preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .web-page-iframe {
    width: 0;
    height: 0;

    &.loaded {
      width: 100%;
      height: 100%;
    }
  }

  .web-page-loader-container {
    position: relative;
    width: 100%;
    height: 100%;

    .web-page-loader {
      width: 100px;
    }
  }

  .web-page-link-wrapper {
    display: flex;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
    background-color: $color-bg-tertiary;

    .web-page-link {
      color: $color-text;
    }
  }
}
</style>
