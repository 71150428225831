// TODO Replace this with a proper super admin role
export const isSuperAdmin = (email: string) => {
  if (!email) {
    return false;
  }

  const allowedDomains = [
    'visualartsweden.onmicrosoft.com',
    'visualart.com',
    'vertiseit.com',
    'dise.com',
    'grassfish.com',
  ];
  const superAdminUsers = [
    'alex.naiqiang-he',
    'alexandra.vasmatzis',
    'andrew.jarder',
    'christian.nylund',
    'emil.brannstrom',
    'fredrik.dejert',
    'guo.yang',
    'johanna.liljecrantz',
    'martin.adolfsson',
    'mattias.westlund',
    'max.ryblad',
    'motti.rapoport',
    'nisse.rozkalns',
    'nils.rozkalns',
    'oliver.carlsson',
    'simon.ciesluk',
    'wilhelm.magnusson',
  ];

  const testUsers = ['caten40092@aosod.com'];

  const isSuperAdmin =
    superAdminUsers.includes(email.split('@')[0]) &&
    allowedDomains.includes(email.split('@')[1]);

  return isSuperAdmin || testUsers.includes(email);
};
