import { v4 as uuidv4 } from 'uuid';

const getFilterNodes = (condition) => {
  var filterNodes = [];
  if (condition?.subNodes) {
    for (var i = 0; i < condition.subNodes.length; i++) {
      var subNode = condition.subNodes[i];
      if (subNode.nodeType === 'And' && subNode.subNodes) {
        for (var j = 0; j < subNode.subNodes.length; j++) {
          let node = subNode.subNodes[j];
          let operator =
            j == subNode.subNodes.length - 1 &&
            i != condition.subNodes.length - 1
              ? 'Or'
              : 'And';
          filterNodes.push({
            node: {
              nodeType: node.nodeType,
              subNodes: null,
              codes: node.codes,
              codeCondition: node.codeCondition,
              nodeKey: node.nodeKey,
            },
            operator: operator,
            id: uuidv4(),
          });
        }
      }
    }
  }
  return filterNodes;
};

const buildFilterCondition = (filterNodes = []) => {
  if (!filterNodes?.length) {
    return null;
  }
  var condition = {
    nodeType: 'Or',
    subNodes: [
      {
        nodeType: 'And',
        subNodes: [],
      },
    ],
  };
  for (var i = 0; i < filterNodes.length; i++) {
    if (i - 1 < 0 || filterNodes[i - 1].operator.toUpperCase() === 'AND') {
      let index = condition.subNodes.length - 1;
      condition.subNodes[index].subNodes.push(filterNodes[i].node);
    } else if (filterNodes[i - 1].operator.toUpperCase() === 'OR') {
      condition.subNodes.push({
        nodeType: 'And',
        subNodes: [filterNodes[i].node],
      });
    }
  }
  return condition;
};

export default {
  getFilterNodes,
  buildFilterCondition,
};
