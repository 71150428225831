export enum BroadcastType {
  STANDARD = 'Normal',
  SHARE_OF_VOICE = 'SoV',
  FILLER = 'Filler',
}

export enum ProofOfPlayReportFormat {
  PDF = 'pdf',
  XLSX = 'xlsx',
}
