<template>
  <va-modal ref="va-modal" class="media-preview-modal" @close="$emit('close')">
    <template #header>
      <h3 class="media-preview-modal-header">
        {{ displayName }}
      </h3>
    </template>
    <template #body>
      <div class="media-preview-modal-body">
        <div class="media-preview-container">
          <va-img
            v-if="mediaTypeImage"
            ref="image-preview"
            class="image-preview"
            :src="previewUrlWithAccessToken"
          />
          <video
            v-if="mediaTypeVideo"
            class="video-preview"
            :src="previewUrlWithAccessToken"
            controls
            autoplay
          />
          <web-page-preview
            v-if="mediaTypeWebpage"
            ref="webpage-preview"
            :url="displayName"
          />
          <sign-preview
            v-if="mediaTypeSign"
            ref="sign-preview"
            :media-code="mediaCode"
          />
        </div>
      </div>
    </template>
  </va-modal>
</template>

<script>
import { userComputed } from '@/services/auth.service';
import VaModal from '@/components/framework/va-modal.vue';
import VaImg from '@/components/framework/va-img.vue';
import WebPagePreview from './WebPagePreview.vue';
import SignPreview from './SignPreview.vue';
import { mediaType } from '@/constants/mediaConstants';

export default {
  components: {
    VaImg,
    WebPagePreview,
    SignPreview,
    VaModal,
  },
  props: {
    mediaCode: {
      type: String,
      default: '',
    },
    mediaType: {
      type: String,
      default: '',
    },
    previewUrl: {
      type: String,
      default: '',
    },
    displayName: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  computed: {
    ...userComputed,
    mediaTypeImage() {
      return this.mediaType === mediaType.IMAGE;
    },
    mediaTypeVideo() {
      return this.mediaType === mediaType.VIDEO;
    },
    mediaTypeSign() {
      return this.mediaType === mediaType.SIGN;
    },
    mediaTypeWebpage() {
      return this.mediaType === mediaType.WEBPAGE;
    },
    previewUrlWithAccessToken() {
      if (this.previewUrl) {
        const token = this.user?.access_token;
        return `${this.previewUrl}?access_token=${token}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.media-preview-modal {
  .media-preview-modal-body {
    height: 80vh;
    background-color: $color-bg;

    .media-preview-container {
      height: 100%;

      .video-preview {
        width: 100%;
        height: 100%;
      }
    }
  }

  :deep() {
    .modal-container {
      max-width: none !important;
      width: calc(100% - 100px);
      margin: 0 50px;
    }

    .image-preview {
      .loader {
        width: 100px;
      }
    }
  }
}
</style>
