<template>
  <div class="preview-orientations">
    <span
      v-for="orientation in orientations"
      :key="orientation.name"
      :class="[
        'preview-orientation',
        { highlighted: orientation.dimensions === value },
      ]"
      data-testid="orientation"
      @click="$emit('change', orientation)"
      >{{ orientation.aspectRatio }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    orientations: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['change'],
};
</script>

<style lang="scss" scoped>
.preview-orientations {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-bg-tertiary;
  height: 20px;
  padding-bottom: 20px;

  .preview-orientation {
    cursor: pointer;
    padding: 0 5px;

    &.highlighted {
      color: $color-text;
    }
  }
}
</style>
