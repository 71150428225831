<template>
  <svg
    width="12px"
    height="15px"
    viewBox="0 0 12 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="bold-icon" fill="currentColor" fill-rule="nonzero">
        <path
          id="B"
          d="M6.92,14.28 C7.56,14.28 8.18333333,14.2 8.79,14.04 C9.39666667,13.88 9.93666667,13.6333333 10.41,13.3 C10.8833333,12.9666667 11.26,12.5366667 11.54,12.01 C11.82,11.4833333 11.96,10.86 11.96,10.14 C11.96,9.24666667 11.7433333,8.48333333 11.31,7.85 C10.8766667,7.21666667 10.22,6.77333333 9.34,6.52 C9.98,6.21333333 10.4633333,5.82 10.79,5.34 C11.1166667,4.86 11.28,4.26 11.28,3.54 C11.28,2.87333333 11.17,2.31333333 10.95,1.86 C10.73,1.40666667 10.42,1.04333333 10.02,0.77 C9.62,0.496666667 9.14,0.3 8.58,0.18 C8.02,0.06 7.4,0 6.72,0 L2.13162821e-14,0 L2.13162821e-14,14.28 L6.92,14.28 Z M6.32,5.78 L3.14,5.78 L3.14,2.44 L6.08,2.44 C6.36,2.44 6.63,2.46333333 6.89,2.51 C7.15,2.55666667 7.38,2.64 7.58,2.76 C7.78,2.88 7.94,3.04666667 8.06,3.26 C8.18,3.47333333 8.24,3.74666667 8.24,4.08 C8.24,4.68 8.06,5.11333333 7.7,5.38 C7.34,5.64666667 6.88,5.78 6.32,5.78 Z M6.5,11.84 L3.14,11.84 L3.14,7.92 L6.56,7.92 C7.24,7.92 7.78666667,8.07666667 8.2,8.39 C8.61333333,8.70333333 8.82,9.22666667 8.82,9.96 C8.82,10.3333333 8.75666667,10.64 8.63,10.88 C8.50333333,11.12 8.33333333,11.31 8.12,11.45 C7.90666667,11.59 7.66,11.69 7.38,11.75 C7.1,11.81 6.80666667,11.84 6.5,11.84 Z"
        />
      </g>
    </g>
  </svg>
</template>
