<template>
  <div class="va-field va-textarea-editor">
    <va-field :is-empty="empty">
      <template #field-data>
        <div class="controls">
          <div class="btn-row">
            <div class="btn-group">
              <button
                v-if="
                  (fieldModel.editor && fieldModel.editor.format) ||
                  typeof fieldModel.editor == 'undefined' ||
                  (fieldModel.editor &&
                    typeof fieldModel.editor.format == 'undefined')
                "
                class="button long format"
                data-command="dropdown"
                data-value="format"
                @blur="closeDropdown"
                @click="handleButton"
              >
                Format
                <div v-if="format" class="btn-dropdown">
                  <button
                    v-for="formatOption in formatOptions"
                    :key="formatOption.value"
                    class="button"
                    data-command="formatBlock"
                    :data-value="formatOption.value"
                    @click="handleButton"
                  >
                    {{
                      formatOption.label[$getUserlanguage()] ||
                      familyOption.label['en_US']
                    }}
                  </button>
                </div>
              </button>
              <button
                v-if="
                  (fieldModel.editor && fieldModel.editor.family) ||
                  typeof fieldModel.editor == 'undefined' ||
                  (fieldModel.editor &&
                    typeof fieldModel.editor.family == 'undefined')
                "
                class="button long family"
                data-command="dropdown"
                data-value="family"
                @blur="closeDropdown"
                @click="handleButton"
              >
                Font-Family
                <div v-if="family" class="btn-dropdown">
                  <button
                    v-for="familyOption in familyOptions"
                    :key="familyOption.value"
                    class="button"
                    data-command="fontName"
                    :data-value="familyOption.value"
                    @click="handleButton"
                  >
                    {{
                      familyOption.label[$getUserlanguage()] ||
                      familyOption.label['en_US'] ||
                      familyOption.value
                    }}
                  </button>
                </div>
              </button>
              <button
                v-if="
                  (fieldModel.editor && fieldModel.editor.color) ||
                  typeof fieldModel.editor == 'undefined' ||
                  (fieldModel.editor &&
                    typeof fieldModel.editor.color == 'undefined')
                "
                class="button long color"
                data-command="dropdown"
                data-value="color"
                @blur="closeDropdown"
                @click="handleButton"
              >
                Font-Color
                <div v-if="color" class="btn-dropdown">
                  <button
                    v-for="colorOption in colorOptions"
                    :key="colorOption.value"
                    :style="{ backgroundColor: colorOption.value }"
                    class="button"
                    data-command="foreColor"
                    :data-value="colorOption.value"
                    @click="handleButton"
                  />
                </div>
              </button>
              <button
                v-if="
                  (fieldModel.editor && fieldModel.editor.size) ||
                  typeof fieldModel.editor == 'undefined' ||
                  (fieldModel.editor &&
                    typeof fieldModel.editor.size == 'undefined')
                "
                class="button long size"
                data-command="dropdown"
                data-value="size"
                @blur="closeDropdown"
                @click="handleButton"
              >
                Font-Size
                <div v-if="size" class="btn-dropdown">
                  <button
                    v-for="sizeOption in sizeOptions"
                    :key="sizeOption.value"
                    class="button"
                    data-command="font_size"
                    :data-value="sizeOption.value"
                    @click="handleButton"
                  >
                    {{
                      sizeOption.label[$getUserlanguage()] ||
                      sizeOption.label['en_US'] ||
                      sizeOption.value
                    }}
                  </button>
                </div>
              </button>
            </div>
          </div>
          <div class="btn-row">
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.bold) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.bold == 'undefined')
              "
              class="button"
              data-command="bold"
              @click="handleButton"
            >
              <svg-icon icon="Bold" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.italic) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.italic == 'undefined')
              "
              class="button"
              data-command="italic"
              @click="handleButton"
            >
              <svg-icon icon="Italic" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.strikeThrough) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.strikeThrough == 'undefined')
              "
              class="button"
              data-command="strikeThrough"
              @click="handleButton"
            >
              <svg-icon icon="Strikethrough" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.underline) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.underline == 'undefined')
              "
              class="button"
              data-command="underline"
              @click="handleButton"
            >
              <svg-icon icon="Underline" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.justifyLeft) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.justifyLeft == 'undefined')
              "
              class="button"
              data-command="justifyLeft"
              @click="handleButton"
            >
              <svg-icon icon="LeftAlign" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.justifyCenter) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.justifyCenter == 'undefined')
              "
              class="button"
              data-command="justifyCenter"
              @click="handleButton"
            >
              <svg-icon icon="CenterAlign" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.justifyRight) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.justifyRight == 'undefined')
              "
              class="button"
              data-command="justifyRight"
              @click="handleButton"
            >
              <svg-icon icon="RightAlign" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.indent) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.indent == 'undefined')
              "
              class="button"
              data-command="indent"
              @click="handleButton"
            >
              <svg-icon icon="Indent" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.outdent) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.outdent == 'undefined')
              "
              class="button"
              data-command="outdent"
              @click="handleButton"
            >
              <svg-icon icon="Outdent" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.insertOrderedList) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.insertOrderedList == 'undefined')
              "
              class="button"
              data-command="insertOrderedList"
              @click="handleButton"
            >
              <svg-icon icon="NumberedList" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.insertUnorderedList) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.insertUnorderedList == 'undefined')
              "
              class="button"
              data-command="insertUnorderedList"
              @click="handleButton"
            >
              <svg-icon icon="BulletList" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.insertHorizontalRule) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.insertHorizontalRule == 'undefined')
              "
              class="button"
              data-command="insertHorizontalRule"
              @click="handleButton"
            >
              <svg-icon icon="HorizontalRule" />
            </button>
            <button
              v-if="
                (fieldModel.editor && fieldModel.editor.blockQuote) ||
                typeof fieldModel.editor == 'undefined' ||
                (fieldModel.editor &&
                  typeof fieldModel.editor.blockQuote == 'undefined')
              "
              class="button"
              data-command="formatBlock"
              data-value="<blockquote>"
              @click="handleButton"
            >
              <svg-icon icon="Quote" />
            </button>
          </div>
        </div>
        <div class="editor-font-styling">
          <div
            ref="vatextarea"
            class="editor"
            contenteditable
            :maxlength="maxLength"
            @keyup="finishEdit"
          />
        </div>
      </template>

      <template #field-label>
        <div class="editor-label">
          {{ computedLabel }}
        </div>
      </template>
    </va-field>
  </div>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon.vue';
export default {
  components: {
    SvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      size: false,
      sizeOptions: [
        { value: '20px', label: { en_US: '20px' } },
        { value: '30px', label: { en_US: '30px' } },
        { value: '40px', label: { en_US: '40px' } },
        { value: '50px', label: {} },
        { value: '60px', label: { en_US: '60px' } },
      ],
      color: false,
      colorOptions: [
        { value: 'Green' },
        { value: 'Red' },
        { value: 'Blue' },
        { value: 'Yellow' },
        { value: '#bababa' },
      ],
      family: false,
      familyOptions: [
        { value: 'Helvetica', label: { en_US: 'Helvetica' } },
        { value: 'Arial', label: { en_US: 'Arial' } },
        { value: 'Times New Roman', label: { en_US: 'Times New Roman' } },
        { value: 'Courier New', label: { en_US: 'Courier New' } },
        { value: 'comic sans ms', label: { en_US: 'Comic Sans' } },
      ],
      format: false,
      formatOptions: [
        { value: '<H1>', label: { sv_SE: 'Rubrik 1', en_US: 'Heading 1' } },
        { value: '<H2>', label: { sv_SE: 'Rubrik 2', en_US: 'Heading 2' } },
        { value: '<H3>', label: { sv_SE: 'Rubrik 3', en_US: 'Heading 3' } },
        { value: '<H4>', label: { sv_SE: 'Rubrik 4', en_US: 'Heading 4' } },
        { value: '<P>', label: { sv_SE: 'Paragraf', en_US: 'Paragraph' } },
      ],
    };
  },
  computed: {
    empty: function () {
      if (!this.dataFieldName) {
        return true;
      }
      return false;
    },
    computedData: function () {
      return this.data;
    },
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    dataFieldName: function () {
      return this.computedData[this.fieldModel.fieldName];
    },
    maxLength: function () {
      if (this.fieldModel.maxLength) {
        return this.fieldModel.maxLength;
      }
      return '';
    },
  },
  mounted() {
    this.$refs.vatextarea.innerHTML = this.dataFieldName;
    this.$refs.vatextarea.addEventListener('paste', this.handlePaste);
  },
  updated() {
    if (this.$refs.vatextarea.innerHTML != this.dataFieldName) {
      this.$refs.vatextarea.innerHTML = this.dataFieldName;
    }
    if (this.fieldModel.editor) {
      if (
        this.fieldModel.editor.size &&
        typeof this.fieldModel.editor.size == 'object'
      ) {
        this.sizeOptions = this.fieldModel.editor.size;
      }
      if (
        this.fieldModel.editor.family &&
        typeof this.fieldModel.editor.family == 'object'
      ) {
        this.familyOptions = this.fieldModel.editor.family;
      }
      if (
        this.fieldModel.editor.format &&
        typeof this.fieldModel.editor.format == 'object'
      ) {
        this.formatOptions = this.fieldModel.editor.format;
      }
      if (
        this.fieldModel.editor.color &&
        typeof this.fieldModel.editor.color == 'object'
      ) {
        this.colorOptions = this.fieldModel.editor.color;
      }
    }
  },
  methods: {
    finishEdit(e) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = e.target.innerHTML;
      this.$emit('updatedData', e.target.innerHTML);
    },
    closeDropdown(e) {
      let value = e.srcElement.getAttribute('data-value');
      setTimeout(() => {
        if (!this.hasSomeParentTheClass(document.activeElement, value)) {
          this[value] = false;
        }
      }, 200);
    },
    closeAllDropdowns() {
      this.size = false;
      this.family = false;
      this.color = false;
      this.format = false;
    },
    hasSomeParentTheClass: function (element, classname) {
      if (
        element.className &&
        element.className.split(' ').indexOf(classname) >= 0
      )
        return true;
      return (
        element.parentNode &&
        this.hasSomeParentTheClass(element.parentNode, classname)
      );
    },
    handleButton(e) {
      let cmd = e.srcElement.getAttribute('data-command');

      let value = e.srcElement.getAttribute('data-value');
      if (!value) value = null;
      if (cmd == 'dropdown') {
        this[value] = !this[value];
      } else if (cmd == 'font_size') {
        this.changeFontSize(value);
      } else if (cmd == 'fontName') {
        document.execCommand(cmd, false, value);
        if (document.querySelector('ol:not([class])') !== null) {
          document.querySelector('ol:not([class])').style.fontFamily = value;
        }
        if (document.querySelector('ul:not([class])') !== null) {
          document.querySelector('ul:not([class])').style.fontFamily = value;
        }
      } else if (cmd === 'foreColor') {
        document.execCommand(cmd, false, value);

        if (document.querySelector('ol:not([class])') !== null) {
          document.querySelector('ol:not([class])').style.color =
            value.toLowerCase();
        }

        if (document.querySelector('ul:not([class])') !== null) {
          document.querySelector('ul:not([class])').style.color =
            value.toLowerCase();
        }
      } else {
        document.execCommand(cmd, false, value);
        this.closeAllDropdowns();
      }
      this.finishEdit({ target: this.$refs.vatextarea });
    },
    changeFontSize(size) {
      document.execCommand('fontSize', false, '7');
      let fontElements = document.getElementsByTagName('font');
      for (var i = 0; i < fontElements.length; ++i) {
        if (fontElements[i].size == '7') {
          fontElements[i].removeAttribute('size');
          fontElements[i].style.fontSize = size;
        }
      }

      if (document.querySelector('ol:not([class])') !== null) {
        document.querySelector('ol:not([class])').style.fontSize = size;
      }
      if (document.querySelector('ul:not([class])') !== null) {
        document.querySelector('ul:not([class])').style.fontSize = size;
      }
      this.closeAllDropdowns();
    },
    handlePaste(e) {
      e.preventDefault();
      const text = (e.clipboardData || window.clipboardData).getData('text');
      const selection = window.getSelection();
      if (selection.rangeCount) {
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(text));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  min-height: 5.4rem;
  color: $color-text;
  padding: 1.2rem 0.8rem 0;
  border: 0;
  background-color: inherit;
  line-height: 1.1;

  :deep(*) {
    font-size: 16px !important;
  }

  :deep(ol) {
    margin-left: 1rem;
    list-style: decimal;
    list-style-type: decimal;
  }

  :deep(ul) {
    margin-left: 1rem;
    list-style: square;
    list-style-type: square;

    li font::before,
    li span::before,
    li > u::before {
      position: relative;
      margin-left: -12px;
      font-size: 18px;
    }
  }
}

.va-textarea-editor {
  :deep(.field) {
    b {
      font-weight: bold;
    }

    i {
      font-style: italic;
    }

    label {
      top: unset;
      bottom: 0;
      width: 100%;

      .editor-label {
        position: absolute;
        bottom: 90px;
        font-size: 0;
      }
    }

    &.empty {
      label {
        .editor-label {
          top: -85px;
          font-size: 0.8rem;
        }
      }
    }
  }
}

.controls {
  width: 100%;
  padding: 10px;
  background-color: $color-bg-tertiary;
  border-bottom: 2px solid $color-bg;
  box-sizing: border-box;

  .btn-row {
    .button {
      position: relative;
      display: inline-block;
      width: 39px;
      height: 39px;
      line-height: 34px;
      font-size: 20px;
      text-align: center;
      background-color: $color-action-bg-secondary;
      color: $color-text-secondary;
      cursor: pointer;
      border: 1px solid $color-action-border-secondary;
      border-radius: 4px;
      margin: 2.3px;
      outline: none;
      vertical-align: top;

      :deep(.svg-icon) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }

      &:hover {
        color: $color-text;
        border-color: $color-action-border-secondary-hover;
      }

      &.long {
        box-sizing: border-box;
        padding-left: 12px;
        width: 120px;
        font-size: 14px;
        line-height: 19px;
        text-align: left;

        &::after {
          position: absolute;
          right: 12px;
          margin-top: 2px;
          content: ' ';
          width: 8px;
          height: 8px;
          border-left: 2px solid $color-fill-secondary;
          border-bottom: 2px solid $color-fill-secondary;
          transform: rotate(-45deg);
        }
      }
    }

    .btn-dropdown {
      position: absolute;
      top: 32px;
      left: 0;
      z-index: 2;
      width: 100%;

      .button {
        font-size: 14px;
        padding: 8px 0;
        height: auto;
        white-space: normal;
        line-height: 1.2;
        display: block;
        border-radius: 0;
        border-width: 1px;
        width: 96%;
        margin-top: -5px;

        &:first-child {
          margin-top: 4px;
        }
      }
    }

    .color {
      .btn-dropdown {
        background-color: $color-action-bg-secondary;
        color: $color-text-secondary;
        border: 1px solid $color-action-border-secondary;
        white-space: normal;
        text-align: justify;

        .button {
          width: 21px;
          height: 21px;
          display: inline-block;
          margin: 4px;
          border-radius: 4px;

          &:hover {
            border: 1px solid $color-action-border-secondary-hover;
          }
        }
      }
    }
  }
}

.editor-font-styling {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  line-height: 0.9rem;
  font-size: 16px !important;
}

.textarea-nonedit {
  height: 5.5rem;
  line-height: 0.9rem;
  color: $color-text-secondary;
  padding: 1.2rem 0.5rem 0;
  overflow: hidden;
  word-break: break-all;

  span {
    display: inline-block;
    color: $color-text;
  }
}
</style>
