<template>
  <div class="va-datepicker">
    <va-popup
      data-testid="va-datepicker-popup"
      :opened="popupOpened"
      @blur="onBlur()"
      @keydown.escape="onBlur()"
    >
      <template #toggler>
        <div
          ref="va-date-main"
          data-testid="va-datepicker"
          class="va-datepicker-input-container"
          @click="onFocus()"
        >
          <span
            data-testid="datepicker"
            :class="{ placeholder: !localDateString }"
          >
            {{ localDateString || $translate('datePicker.placeholder') }}
          </span>
          <button
            v-if="dValue"
            class="close-icon"
            data-testid="date-picker--clear"
            @click.stop="clear"
          >
            <svg-icon icon="close" class="clickable hover-effect-highlight" />
          </button>
          <button class="calendar-icon">
            <svg-icon icon="calendar" color="white" />
          </button>
        </div>
      </template>
      <div
        ref="popup-content"
        :class="['va-date-dropdown', { 'no-time': !hasTime }]"
        tabindex="-1"
      >
        <datepicker-header
          ref="datepicker-header"
          :display-month="displayMonth"
          :display-year="displayYear"
          @prev-month="prevMonth()"
          @next-month="nextMonth()"
          @close="onBlur()"
        />
        <div class="calendar">
          <weekdays />
          <weeks
            ref="datepicker-weeks"
            :selected-date="selected"
            :display-month="displayMonth"
            :display-year="displayYear"
            @select-day="selectDay"
            @close="onBlur()"
          />
        </div>
        <div v-if="hasTime" class="select-time">
          <span>{{ selectTimeLabel }}</span>
          <va-timepicker
            ref="timepicker"
            v-model="time"
            data-testid="va-datepicker--time-picker"
            class="calendar-timepicker"
          />
        </div>
      </div>
    </va-popup>
  </div>
</template>

<script>
import Weeks from '@/components/framework/va-datepicker/va-weeks.vue';
import Weekdays from '@/components/framework/va-datepicker/va-weekdays.vue';
import DatepickerHeader from '@/components/framework/va-datepicker/va-datepicker-header.vue';

import VaTimepicker from '@/components/framework/va-timepicker.vue';
import VaPopup from '@/components/framework/vaPopup/VaPopup.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import { dateToLocaleString } from '@/helpers/localization';
import {
  value,
  updateValue,
  displayValue,
} from '@/components/framework/va-datepicker/datepicker';

export default {
  components: {
    VaTimepicker,
    VaPopup,
    SvgIcon,
    Weeks,
    Weekdays,
    DatepickerHeader,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    selectTimeLabel: {
      type: String,
      default: '',
    },
    defaultTime: {
      type: String,
      default: '',
    },
    hasTime: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      popupOpened: false,
      dValue: '',
      selected: new Date(),
      displayMonth: 1,
      displayYear: 2021,
      time: '',
    };
  },
  computed: {
    localDate() {
      if (!this.dValue) {
        return '';
      }
      const dateSplit = this.dValue.split('-');
      const timeSplit = this.time ? this.time.split(':') : [];
      return new Date(
        dateSplit[0],
        dateSplit[1] - 1,
        dateSplit[2],
        ...timeSplit,
      );
    },
    localDateString() {
      if (!this.localDate) {
        return '';
      }
      return dateToLocaleString(this.localDate.toString(), false, this.hasTime);
    },
  },
  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          return [(this.dValue = ''), (this.time = '')];
        }
        const refracture = newVal.split(' ');
        this.dValue = refracture[0] || '';
        this.time = refracture[1] || '';
      },
    },
  },
  methods: {
    onFocus() {
      this.updateCalendar();
      this.popupOpened = true;
    },
    updateCalendar() {
      const date = this.localDate || new Date();

      this.selected = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
      );
      this.displayYear = date.getFullYear();
      this.displayMonth = date.getMonth() + 1;
    },
    onBlur() {
      if (!this.hasTime) {
        this.$emit('update:modelValue', displayValue(this.dValue));
      } else {
        this.$emit(
          'update:modelValue',
          updateValue(this.dValue, this.time, this.defaultTime),
        );
      }
      this.popupOpened = false;
    },
    prevMonth() {
      this.displayMonth--;
      if (this.displayMonth === 0) {
        this.displayMonth = 12;
        this.displayYear--;
      }
      this.$refs['popup-content'].focus();
    },
    nextMonth() {
      this.displayMonth++;
      if (this.displayMonth === 13) {
        this.displayMonth = 1;
        this.displayYear++;
      }
      this.$refs['popup-content'].focus();
    },
    selectDay(day, month = 0) {
      let year = this.displayYear;
      month = this.displayMonth + month;
      if (month === 0) {
        month = 12;
        year--;
      } else if (month === 13) {
        month = 1;
        year++;
      }
      this.dValue = value(year, month, day);
      this.updateCalendar();
    },
    clear() {
      this.dValue = '';
      this.onBlur();
    },
  },
};
</script>

<style lang="scss" scoped>
.va-datepicker {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  color: $color-text-secondary;
  cursor: pointer;

  &-input-container {
    position: relative;
    padding: 0.4rem;
    height: 1.875rem;
    min-width: 180px;
    background-color: $color-input;
    color: $color-text;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0.6px;
    text-align: left;
    display: flex;
    align-items: center;

    &::placeholder {
      color: $color-text-secondary;
    }

    .calendar-icon {
      cursor: pointer;
      position: absolute;
      right: 0.4rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.25rem;
      border: none;
      background: transparent;
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: center;
    }

    .close-icon {
      color: $color-text-secondary;
      background: transparent;
      border: none;
      padding: 0 0.3rem;
      font-size: 0.6rem;
      display: flex;
      align-items: center;
      line-height: 1;
      transition: color 0.2s ease-out;
    }

    .placeholder {
      color: $color-text-secondary;
    }
  }

  .va-date-dropdown {
    background: $color-input;
    margin-top: -4px;
    padding-top: 10px;
    box-shadow: 0 3px 10px $color-shadow;

    &.no-time {
      padding-bottom: 1rem;
    }

    &:focus {
      outline: none;
    }

    .calendar {
      padding: 0 1rem;
    }

    .select-time {
      padding: 9px 1rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .calendar-timepicker {
      border: 1px solid $color-border-secondary;
    }
  }
}
</style>
